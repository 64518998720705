import "./App.css";
import Main from "./Main";
import BirthdayWish from "./BirthdayWish";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignupWithGoogle from "./SignupWithGoogle";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="wish-birthday" element={<BirthdayWish />} />
        <Route path="/signup" element={<SignupWithGoogle clientId="202249171786-5kdrlir9i1081kiv4lpm8tsdhs0h042e.apps.googleusercontent.com" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
