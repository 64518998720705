import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import "./SignupWithGoogle.css";

const SignupWithGoogle = ({ clientId, onSignupSuccess, onSignupFailure }) => {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // Check if a valid token is present in cookies
    const token = Cookies.get("authToken");
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const user = {
          name: decodedToken.name,
          email: decodedToken.email,
        };
        setUserDetails(user);
      } catch (error) {
        console.error("Invalid token, clearing cookie.");
        Cookies.remove("authToken"); // Clear invalid token
      }
    }
  }, []);

  const handleSuccess = (credentialResponse) => {
    const { credential } = credentialResponse;

    // Decode JWT Token to get user information
    const decodedToken = JSON.parse(atob(credential.split(".")[1]));
    console.log("User Info:", decodedToken);

    const user = {
      name: decodedToken.name,
      email: decodedToken.email,
    };

    // Save token in cookie
    Cookies.set("authToken", credential, { expires: 1 }); // Expires in 1 days

    setUserDetails(user);

    if (onSignupSuccess) {
      onSignupSuccess(user);
    }
  };

  const handleError = () => {
    console.error("Signup Failed");
    if (onSignupFailure) {
      onSignupFailure();
    }
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="signup-container">
        {!userDetails ? (
          <>
            <h2>Sign Up with Google</h2>
            <GoogleLogin
              onSuccess={handleSuccess}
              onError={handleError}
              useOneTap
            />
          </>
        ) : (
          <div className="user-details">
            <h3>Welcome, {userDetails.name}!</h3>
            <p>
              <strong>Email:</strong> {userDetails.email}
            </p>
            <p>You are successfully logged in.</p>
          </div>
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignupWithGoogle;
