import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faDownload,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
const Main = () => {
  return (
    <div className="App">
      <div className="leftPanel">
        <h1>Nitesh Agrawal</h1>
        <h3>Software Engineer</h3>
        <div className="social-icons">
          <a
            href="mailto:nkagrawal14@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            href="https://www.linkedin.com/in/ismnitesh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://drive.google.com/file/d/1leG7o4aHuxrs-eqiuHcghaN5apsRpIiq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
          <a
            href="https://calendly.com/ismnitesh/60-min-discussion"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faCalendar} />
          </a>
        </div>
      </div>
      <div className="rightPanel">
        <section className="experience">
          <h2>Experience</h2>
          <div className="job">
            <h3>Repute Network - Lead Software Engineer</h3>
            <p className="job-duration">Nov 2021 - Present</p>
            <ul>
              <li>
                Lead the development of Repute marketpalce and third party apps
                integration.
              </li>
              <li>
                Built web extraction framework from scratch and developed
                extraction for many HRMS and GIG services for EIVG service
              </li>
              <li>
                Mentored junior developers, improving team productivity and
                project management.
              </li>
            </ul>
          </div>
          <div className="job">
            <h3>PhonePe - Software Engineer</h3>
            <p className="job-duration">Aug 2018 - Oct 2021</p>
            <ul>
              <li>Worked on multiple features in payments team.</li>
              <li>
                Added multi node deployment feature and email feature on
                deployment script
              </li>
            </ul>
          </div>

          <div className="job">
            <h3>OlaCabs - Software Engineer</h3>
            <p className="job-duration">Apr 2017 - Jul 2018</p>
            <ul>
              <li>Worked with Ola share team.</li>
            </ul>
          </div>

          <div className="job">
            <h3>Amazon - Software Engineer</h3>
            <p className="job-duration">Jul 2016 - Mar 2017</p>
            <ul>
              <li>Worked with RBS (Retail business system) team.</li>
            </ul>
          </div>
        </section>

        <section id="education">
          <h2>Education</h2>
          <h3>Indian Institute Of Technology (ISM) Dhanbad</h3>
          <p className="education-degree">
            B.Tech Computer Science and Engineering
          </p>
        </section>
        <section id="skills">
          <h2>Skills</h2>
          <ul>
            <li>Java, Python, JavaScript</li>
            <li>MySQL, RDS, DynamoDB</li>
            <li>Spring, DropWizard</li>
            <li>Kubernetes, Docker, Jenkins, Git, AWS(S3, RDS, EC2, SQS)</li>
            <li>Kafka, RabbitMQ, Redis, Maven</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Main;
