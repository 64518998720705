import React from "react";
import Confetti from "react-confetti";
import "./BirthdayWish.css";

const BirthdayWish = () => {
  return (
    <div className="birthday-wish-container">
      <Confetti />
      <div className="birthday-wish-content">
        <h1>Happy Birthday, Prerna!</h1>
        <p>Wishing you a fantastic day filled with joy and happiness!</p>
      </div>
    </div>
  );
};

export default BirthdayWish;
